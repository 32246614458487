import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

enum FeatureTabs {
  CREATE = 'create',
  REMIND = 'remind',
  SIGN = 'sign',
  TRACK = 'track',
}
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
  ],
  templateUrl: './home.page.html',
  styleUrl: './home.page.scss'
})
export class HomePage {
  featureTabs = FeatureTabs;
  featureActiveTab = FeatureTabs.CREATE;

  setFeatureTab(tab: FeatureTabs) {
    this.featureActiveTab = tab;
  }

  getActiveTabImage() {
    return `assets/images/homepage-features-${this.featureActiveTab}.png`;
  }

  getActiveTabCaption() {
    return `${this.featureActiveTab} feature`;
  }
}
