<main class="lp-pricing" id="pricing">
  <div class="lp-container">
    <!-- Section Pricing -->
    <div class="lp-section lp-section-pricing">
      <div class="lp-section__tag lp-badge">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <path d="M2.3335 7.44106V3.16668C2.3335 2.4303 2.93045 1.83334 3.66683 1.83334H7.94121C8.29483 1.83334 8.63397 1.97382 8.88402 2.22387L14.224 7.56387C14.7447 8.08457 14.7447 8.92879 14.224 9.44949L9.94964 13.7239C9.42894 14.2446 8.58472 14.2446 8.06402 13.7239L2.72402 8.38387C2.47397 8.13382 2.3335 7.79468 2.3335 7.44106Z" stroke="#6C6CC6" stroke-width="1.5" stroke-linejoin="round"/>
          <path d="M6.00016 5.00001C6.00016 5.27615 5.77631 5.50001 5.50016 5.50001C5.22402 5.50001 5.00016 5.27615 5.00016 5.00001C5.00016 4.72387 5.22402 4.50001 5.50016 4.50001C5.77631 4.50001 6.00016 4.72387 6.00016 5.00001Z" stroke="#6C6CC6" stroke-width="1.5" stroke-linejoin="round"/>
        </svg>

        <span i18n>
          {{ '5080775557941296581' | translate }}
        </span>
      </div>
      <h1 i18n class="lp-section__title">
        {{ '5201252876409744399' | translate }}
      </h1>
      <p i18n class="lp-section__subtitle">
        {{ '5665682078974119899' | translate }}
      </p>

      <div class="lp-section-pricing-toggle">
        <button
          class="lp-section-pricing-toggle__button"
          [ngClass]="{
            'active': isMonthlyPrice
          }"
          (click)="toggleMonthlyPrice()"
        >
          <span i18n>
            {{ '3745223847280240111' | translate }}
          </span>
        </button>
        <button
          class="lp-section-pricing-toggle__button"
          [ngClass]="{
            'active': !isMonthlyPrice
          }"
          (click)="toggleMonthlyPrice()"
        >
          <span i18n>
            {{ '3745223847280240225' | translate }}
          </span> &nbsp; <span class="tag">-30%</span>
        </button>
      </div>

      <div class="lp-section-pricing-list">
        <div class="lp-section-pricing-list__card">
          <h5 class="lp-section-pricing-list__card-category" i18n>
            {{ '8643289769990675407' | translate }}
          </h5>
          <div class="lp-bg-light lp-rounded-2xl lp-p-4 lp-border lp-border-light-border">
            <div class="lp-flex lp-items-center lp-gap-2">
              <h4 class="lp-section-pricing-list__card-title">
                €{{ getPriceMonthly('basic') }}
              </h4>
              <div>
                <p class="lp-section-pricing-list__card-caption" i18n>
                  {{ '2782999419864929064' | translate }}
                </p>
                <p class="lp-section-pricing-list__card-caption" i18n>
                  {{ '4457724258529415204' | translate }}
                </p>
              </div>
            </div>
            <p class="lp-section-pricing-list__card-caption lp-my-2">
              <span *ngIf="isMonthlyPrice" i18n>
                {{ '121488628441303754' | translate }}
              </span>

              <span *ngIf="!isMonthlyPrice">{{ '€' + getPrice('basic') }} &nbsp;</span>
              <span *ngIf="!isMonthlyPrice" i18n>
                {{ '1575500558495857787' | translate }}
              </span>
            </p>
            <hr class="lp-my-4">
            <p class="lp-section-pricing-list__card-caption lp-mb-3" i18n>
              {{ '1495305152743827327' | translate }}
            </p>
            <ul>
              <li i18n>{{ '1053588451866981488' | translate }}</li>
              <li i18n>{{ '3584000499329874189' | translate }}</li>
              <li i18n>{{ '3949722752554897754' | translate }}</li>
              <li i18n>{{ '1291201054253562691' | translate }}</li>
              <li i18n>{{ '9184152395221564381' | translate }}</li>
            </ul>
            <button class="lp-button" i18n>{{ '2045779543869682721' | translate }}</button>
          </div>
        </div>

        <div class="lp-section-pricing-list__card">
          <h5 class="lp-section-pricing-list__card-category" i18n>
            {{ '2798807656507405918' | translate }}
          </h5>
          <div class="lp-bg-light lp-rounded-2xl lp-p-4 lp-border lp-border-light-border">
            <div class="lp-flex lp-items-center lp-gap-2">
              <h4 class="lp-section-pricing-list__card-title">
                €{{ getPriceMonthly('standard') }}
              </h4>
              <div>
                <p class="lp-section-pricing-list__card-caption" i18n>
                  {{ '2782999419864929064' | translate }}
                </p>
                <p class="lp-section-pricing-list__card-caption" i18n>
                  {{ '4457724258529415204' | translate }}
                </p>
              </div>
            </div>
            <p class="lp-section-pricing-list__card-caption lp-my-2">
              <span *ngIf="isMonthlyPrice" i18n>
                {{ '121488628441303754' | translate }}
              </span>

              <span *ngIf="!isMonthlyPrice">{{ '€' + getPrice('standard') }} &nbsp;</span>
              <span *ngIf="!isMonthlyPrice" i18n>
                {{ '1575500558495857787' | translate }}
              </span>
            </p>
            <hr class="lp-my-4">
            <p class="lp-section-pricing-list__card-caption lp-mb-3">
              <span i18n>
                {{ '7202955359621782188' | translate }}
              </span>&nbsp;
              <span i18n class="highlighted">{{ '8643289769990675407' | translate }}</span>&nbsp;
              <span i18n>{{ '7715719106284590680' | translate }}</span>
            </p>
            <ul>
              <li i18n>{{ '1673623004371758637' | translate }}</li>
              <li i18n>{{ '3158243251100536416' | translate }}</li>
              <li i18n>{{ '4955103017094349788' | translate }}</li>
            </ul>
            <button class="lp-button" i18n>{{ '2045779543869682721' | translate }}</button>
          </div>
        </div>

        <div class="lp-section-pricing-list__card lp-section-pricing-list__card-popular">
          <div class="lp-flex lp-items-center lp-justify-between lp-mb-2">
            <h5 class="lp-section-pricing-list__card-category" i18n>
              {{ '7722828221329169436' | translate }}
            </h5>
            <span class="lp-section-pricing-list__card-tag">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M5.6665 5.16667L4.1665 6.66667L5.6665 8.16667M9.41405 12.5858L13.9029 8.09695C14.6902 7.30965 14.683 6.03099 13.8868 5.25263L11.6542 3.0699C11.2805 2.70456 10.7787 2.5 10.256 2.5H5.74363C5.22102 2.5 4.71918 2.70456 4.34548 3.0699L2.11286 5.25263C1.31672 6.03099 1.30949 7.30965 2.09679 8.09695L6.58562 12.5858C7.36667 13.3668 8.633 13.3668 9.41405 12.5858Z" stroke="#1516A4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <span i18n>
                {{ '7246104179228542701' | translate }}
              </span>
            </span>
          </div>
          <div class="lp-bg-light lp-rounded-2xl lp-p-4 lp-border lp-border-light-border">
            <div class="lp-flex lp-items-center lp-gap-2">
              <h4 class="lp-section-pricing-list__card-title">
                €{{ getPriceMonthly('professional') }}
              </h4>
              <div>
                <p class="lp-section-pricing-list__card-caption" i18n>
                  {{ '2782999419864929064' | translate }}
                </p>
                <p class="lp-section-pricing-list__card-caption" i18n>
                  {{ '4457724258529415204' | translate }}
                </p>
              </div>
            </div>
            <p class="lp-section-pricing-list__card-caption lp-my-2">
              <span *ngIf="isMonthlyPrice" i18n>
                {{ '121488628441303754' | translate }}
              </span>

              <span *ngIf="!isMonthlyPrice">{{ '€' + getPrice('professional') }} &nbsp;</span>
              <span *ngIf="!isMonthlyPrice" i18n>
                {{ '1575500558495857787' | translate }}
              </span>
            </p>
            <hr class="lp-my-4">
            <p class="lp-section-pricing-list__card-caption lp-mb-3">
              <span i18n>{{ '7202955359621782188' | translate }}</span>&nbsp;
              <span i18n class="highlighted">{{ '2798807656507405918' | translate }}</span>&nbsp;
              <span i18n>{{ '7715719106284590680' | translate }}</span>
            </p>
            <ul>
              <li i18n>{{ '2009981986611747122' | translate }}</li>
              <li i18n>{{ '293951737029743838' | translate }}</li>
              <li i18n>{{ '936577409463347653' | translate }}</li>
            </ul>
            <button class="lp-button lp-button-primary" i18n>{{ '2045779543869682721' | translate }}</button>
          </div>
        </div>

        <div class="lp-section-pricing-list__card">
          <h5 class="lp-section-pricing-list__card-category" i18n>
            {{ '8041427397457443304' | translate }}
          </h5>
          <div class="lp-bg-light lp-rounded-2xl lp-p-4 lp-border lp-border-light-border">
            <div class="lp-flex lp-items-center lp-gap-2">
              <h4 class="lp-section-pricing-list__card-title" i18n>
                {{ '217728430546932256' | translate }}
              </h4>
              <div>
                <p class="lp-section-pricing-list__card-caption">&nbsp;</p>
                <p class="lp-section-pricing-list__card-caption">&nbsp;</p>
              </div>
            </div>
            <hr class="lp-my-4">
            <p class="lp-section-pricing-list__card-caption lp-mb-3">
              <span i18n>
                {{ '7202955359621782188' | translate }}
              </span>&nbsp;
              <span i18n class="highlighted">{{ '7722828221329169436' | translate }}</span>&nbsp;
              <span i18n>{{ '7715719106284590680' | translate }}</span>
            </p>
            <ul class="lp-mb-2">
              <li i18n>{{ '8609902496159323952' | translate }}</li>
              <li i18n>{{ '6379393829798725596' | translate }}</li>
              <li i18n>{{ '5685455312615556123' | translate }}</li>
              <li i18n>{{ '2223640905439252475' | translate }}</li>
              <li i18n>{{ '5097567714151463833' | translate }}</li>
              <li i18n>{{ '814810044879675300' | translate }}</li>
            </ul>
            <button class="lp-button" i18n>{{ '759687838251957893' | translate }}</button>
          </div>
        </div>
      </div>

      <div class="lp-section-pricing-contact">
        <figure>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9.49977 21C10.8798 22.333 13.1198 22.333 14.4998 21M16.9998 13.805C16.9998 13.459 16.9998 13.286 17.0518 13.132C17.2028 12.684 17.6018 12.511 18.0018 12.329C18.4498 12.124 18.6738 12.022 18.8968 12.004C19.1488 11.984 19.4018 12.038 19.6178 12.159C19.9038 12.319 20.1038 12.625 20.3078 12.873C21.2508 14.019 21.7228 14.592 21.8948 15.223C22.0348 15.733 22.0348 16.267 21.8948 16.776C21.6438 17.698 20.8488 18.47 20.2598 19.186C19.9588 19.551 19.8078 19.734 19.6178 19.841C19.3981 19.9628 19.1471 20.0168 18.8968 19.996C18.6738 19.978 18.4498 19.876 18.0008 19.671C17.6008 19.489 17.2028 19.316 17.0518 18.868C16.9998 18.714 16.9998 18.541 16.9998 18.196V13.805ZM6.99977 13.805C6.99977 13.369 6.98777 12.978 6.63577 12.672C6.50777 12.561 6.33777 12.484 5.99877 12.329C5.54977 12.125 5.32577 12.022 5.10277 12.004C4.43577 11.95 4.07677 12.406 3.69277 12.874C2.74877 14.019 2.27677 14.592 2.10377 15.224C1.96476 15.732 1.96476 16.268 2.10377 16.776C2.35577 17.698 3.15177 18.47 3.73977 19.186C4.11077 19.636 4.46577 20.047 5.10277 19.996C5.32577 19.978 5.54977 19.876 5.99877 19.671C6.33877 19.516 6.50777 19.439 6.63577 19.328C6.98777 19.022 6.99977 18.631 6.99977 18.195V13.805Z" stroke="#73738C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 16V12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12V16" stroke="#73738C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </figure>
        <div>
          <h4 i18n>{{ '207665790273814216' | translate }}</h4>
          <p i18n>{{ '7829998234433326842' | translate }}</p>
        </div>
        <button class="lp-button" i18n>{{ '4534695346581612772' | translate }}</button>
      </div>
    </div>
    <!-- End Section Pricing -->

    <!-- Section FAQ -->
    <div class="lp-section lp-section-faq">
      <div class="lp-section__tag lp-badge">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <path d="M7.00016 6.16671C7.00016 5.61442 7.44788 5.16671 8.00016 5.16671H8.80595C9.4655 5.16671 10.0002 5.70137 10.0002 6.36092C10.0002 6.76021 9.80061 7.13308 9.46838 7.35456L9.0939 7.60422C8.72296 7.85151 8.50016 8.26782 8.50016 8.71362V8.83337M8.50016 10.6667V10.66M14.6668 8.00004C14.6668 11.4058 11.9059 14.1667 8.50016 14.1667C5.09441 14.1667 2.3335 11.4058 2.3335 8.00004C2.3335 4.59428 5.09441 1.83337 8.50016 1.83337C11.9059 1.83337 14.6668 4.59428 14.6668 8.00004ZM8.66683 10.6667C8.66683 10.7588 8.59221 10.8334 8.50016 10.8334C8.40812 10.8334 8.3335 10.7588 8.3335 10.6667C8.3335 10.5747 8.40812 10.5 8.50016 10.5C8.59221 10.5 8.66683 10.5747 8.66683 10.6667Z" stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span i18n>{{ '2806917038528218276' | translate }}</span>
      </div>
      <h1 class="lp-section__title" i18n>
        {{ '7563204181659642206' | translate }}
      </h1>

      <div class="lp-section-faq-list">
        <div>
          <app-accordion
            i18n
            i18n-title
            [title]="'6009923283430216280' | translate"
          >
            {{ '2905619311922040801' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'499402269809813892' | translate"
          >
            {{ '8896951206497638827' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'3637916639801785541' | translate"
          >
            {{ '7357066375644015074' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'5140634688481240801' | translate"
          >
            {{ '7042376496413445435' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'5301733592885640326' | translate"
          >
            {{ '1883511822821885824' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'8938509360458980463' | translate"
          >
            {{ '7260403968182309461' | translate }}
          </app-accordion>
        </div>
        <div>
          <app-accordion
            i18n
            i18n-title
            [title]="'5480247650210565580' | translate"
          >
            {{ '717699067745470137' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'8908736649778919485' | translate"
          >
            {{ '3499116226701483042' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'8309388647864491915' | translate"
          >
            {{ '7562483400628090920' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'3937158223272729405' | translate"
          >
            {{ '6323551433529550194' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'735410217015418006' | translate"
          >
            {{ '4290621948970340970' | translate }}
          </app-accordion>
          <app-accordion
            i18n
            i18n-title
            [title]="'2789241429940574949' | translate"
          >
            {{ '7458704842596586012' | translate }}
          </app-accordion>
        </div>
      </div>
    </div>
    <!-- End Section FAQ -->
  </div>
</main>