import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {environment} from "@environments/environment";

@Component({
  selector: 'app-header-esign',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslateModule],
  templateUrl: './esign-header.layout.html',
  styleUrl: './esign-header.layout.scss'
})
export class EsignHeaderComponent {
  fixedHeader = false;
  isMobile = false;
  isMenuOpen = false;
  eSignAppPortalUrl = environment.eSignAppPortalUrl;
  eSignAppSsoUrl = environment.eSignAppSsoUrl;

  @HostListener('window:resize', ['$event'])
  checkIsMobile() {
    if (typeof window === "undefined") return;

    this.isMobile = window.innerWidth <= 1024;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (typeof window === "undefined") return;

    if (window.scrollY > 64 && !this.isMenuOpen) {
      this.fixedHeader = true;
    } else {
      this.fixedHeader = false;
    }
  }

  constructor(
    private router: Router,
  ) {
    this.checkIsMobile();

    this.router.events.subscribe((val) => {
      if (this.isMobile) this.toggleMenu();
    });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
