import { Routes } from '@angular/router';

import { setLayout } from '@resolver/layout.resolver';

import { Layout } from '@enum/layout.enum';

import { HomePage } from '@page/home/home.page';
import { PricingPage } from '@page/pricing/pricing.page';
import { BlogPage } from '@page/dms/blog/blog.page';

export const routes: Routes = [
  {
    path: '',
    component: HomePage,
    resolve: {
      layout: setLayout(Layout.ESIGN)
    }
  },
  {
    path: 'pricing',
    component: PricingPage,
    resolve: {
      layout: setLayout(Layout.ESIGN)
    }
  },
  {
    path: 'dms/blog',
    component: BlogPage,
    resolve: {
      layout: setLayout(Layout.DMS)
    }
  },
  {
    path: '**',
    component: HomePage,
    resolve: {
      layout: setLayout(Layout.ESIGN)
    }
  }
];
