<header class="dms-header lp-container">
  <a routerLink="/">
    <img
      src="assets/images/logo-dms.png"
      class="dms-header__logo"
      alt="DMS Workspace">
  </a>

  <a routerLink="/" class="lp-button" i18n>
    {{ '5765021025760241348' | translate }}
  </a>
</header>