import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';

import { LayoutService } from '@service/layout.service';

import { Layout } from '@enum/layout.enum';

export const setLayout = (inputLayout: Layout): ResolveFn<void> => {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
      inject(LayoutService).setLayout(inputLayout)
  };
}
