import { Component } from '@angular/core';

import { EsignHeaderComponent } from './header/esign-header.layout';
import { EsignFooterComponent } from './footer/esign-footer.layout';

@Component({
  selector: 'app-esign',
  standalone: true,
  imports: [EsignHeaderComponent, EsignFooterComponent],
  templateUrl: './esign.layout.html',
  styleUrl: './esign.layout.scss'
})
export class EsignLayout {

}
