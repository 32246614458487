<main class="dms-blog">
  <div class="dms-blog-header">
    <div class="lp-container">
      <div class="dms-blog-title">
        <p class="lp-badge lp-badge-dms">
          <span class="mat-icon">
            book_2
          </span>
          <span i18n>
            {{ '5258009047118836958' | translate }}
          </span>
        </p>
        <h1 i18n>{{ '7968343663045227628' | translate }}</h1>
      </div>
      <div class="dms-blog-newsletter">
        <label i18n class="lp-label">{{ '906115046782302936' | translate }}</label>
        <input
          i18n-placeholder
          class="lp-input"
          type="email"
          [placeholder]="'203827529942340818' | translate"
          [formControl]="email"
          email
        >
        <p
          *ngIf="emailErrors || (email.value && email.errors)"
          class="lp-input__error"
          i18n
        >{{ '5448447574828483730' | translate }}</p>
        <div class="lp-input__consent">
          <input type="checkbox" [formControl]="consent">
          <div>
            <span i18n>
              {{ '8262701550369119943' | translate }}
            </span>
            <a href="#" class="lp-text-primary" i18n>{{ '8439955599488894226' | translate }}</a>.
          </div>
        </div>
        <button
          class="lp-button lp-button-dms"
          [disabled]="!consent.value || email.status === 'INVALID'"
          (click)="subscribe()"
        >
          <span *ngIf="isSubscribed" i18n>{{ '107541064195755149' | translate }}</span>
          <span *ngIf="!isSubscribed" i18n>{{ '1144407473317535723' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="lp-container">
    <div *ngIf="blogs.length > 0;else empty_state" class="dms-blog-list">
      @for (blog of blogs; track blog) {
        <a [href]="blog.link" target="_blank">
          <div class="dms-blog-list__item">
            <img [src]="blog.image" alt="Thumbnail">
            <div class="dms-blog-list__item-details">
              <span>{{ blog.category }}</span>
              <span>&bullet;</span>
              <span>{{ formattedDate(blog.date) }}</span>
            </div>
            <h4
              class="dms-blog-list__item-title"
              [innerHTML]="blog.title.rendered"></h4>
            <div
              class="dms-blog-list__item-excerpt"
              [innerHTML]="blog.excerpt.rendered"></div>
          </div>
        </a>
      }
    </div>

    <ng-template #empty_state>
      <div class="dms-blog-empty">
        <img src="assets/images/blog-empty.png" alt="Blog Empty">
        <h4 i18n>{{ '7519671962502580540' | translate }}</h4>
        <p i18n>{{ '8088440401551563409' | translate }}</p>
      </div>
    </ng-template>
  </div>
</main>