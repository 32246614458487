import { CommonModule, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BlogService } from '@service/blog.service';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  providers: [BlogService],
  templateUrl: './blog.page.html',
  styleUrl: './blog.page.scss'
})
export class BlogPage implements OnInit {
  blogs: any[] = [];

  consent = new FormControl(false, [
    Validators.required,
  ]);
  email = new FormControl('', [
    Validators.required,
    Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')
  ]);
  emailErrors = false;
  isSubscribed = false;

  constructor(private blogService: BlogService) { }

  ngOnInit() {
    this.blogService.getBlogs()
      .subscribe((blogs) => {
        if (blogs.length) {
          let blogsWithImage: any[] = [];

          blogs.forEach((blog) => {
            blogsWithImage.push({
              ...blog,
              image: this.getImage(blog.featured_media),
              category: this.getCategory(blog.categories[0]),
            })
          });

          this.blogs = blogsWithImage;
        }
      });
  }

  getImage(id: number) {
    let imageUrl = 'assets/images/blog-image-fallback.png';

    this.blogService.getImage(id)
      .subscribe((res) => {
        if (
          res &&
          res.media_details &&
          res.media_details.sizes &&
          res.media_details.sizes.medium &&
          res.media_details.sizes.medium.source_url
        ) {
          imageUrl = res.media_details.sizes.medium.source_url
        }
      });

    return imageUrl;
  }

  getCategory(categoryId: number) {
    let category = 'Uncategorized';

    this.blogService.getCategory(categoryId)
      .subscribe((res) => {
        if (
          res &&
          res[0] &&
          res[0].name
        ) {
          category = res[0].name;
        }
      });

    return category;
  }

  formattedDate(date: string) {
    const dateTime = new Date(date);
    const pattern = 'dd MMM yyyy';
    const locale = 'en-UK';
    return formatDate(dateTime, pattern, locale);
  }

  subscribe() {
    if (this.email.value && this.email.status !== 'INVALID')  {
      this.blogService.subscribeEmail(this.email.value)
        .subscribe((res) => {
          if (res) {
            this.isSubscribed = true;
          }
        });

      return;
    }

    this.emailErrors = true;
  }
}
