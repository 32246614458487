import { Component } from '@angular/core';

import { DmsHeaderComponent } from './header/dms-header.layout';
import { DmsFooterComponent } from './footer/dms-footer.layout';

@Component({
  selector: 'app-dms',
  standalone: true,
  imports: [DmsHeaderComponent, DmsFooterComponent],
  templateUrl: './dms.layout.html',
  styleUrl: './dms.layout.scss'
})
export class DmsLayout {

}
