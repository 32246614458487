import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { AccordionComponent } from "@component/accordion/accordion.layout";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-pricing',
  standalone: true,
  imports: [CommonModule, RouterLink, AccordionComponent, TranslateModule],
  templateUrl: './pricing.page.html',
  styleUrl: './pricing.page.scss'
})
export class PricingPage {
  isMonthlyPrice = true;
  prices = {
    basic: {
      monthly: 14,
      annually: 117.6,
    },
    standard: {
      monthly: 25,
      annually: 210,
    },
    professional: {
      monthly: 35,
      annually: 294,
    },
  };

  getPrice(packageName: string) {
    let price = this.prices.basic;

    switch (packageName) {
      case 'standard':
        price = this.prices.standard;
        break;

      case 'professional':
        price = this.prices.professional;
        break;

      default:
        price = this.prices.basic;
        break;
    }

    return this.isMonthlyPrice
      ? price.monthly
      : price.annually;
  }

  getPriceMonthly(packageName: string) {
    return this.isMonthlyPrice
      ? this.getPrice(packageName)
      : (this.getPrice(packageName) / 12).toFixed(1);
  }

  toggleMonthlyPrice() {
    this.isMonthlyPrice = !this.isMonthlyPrice;
  }
}
