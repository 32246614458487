<header
  class="esign-header lp-container"
  [ngClass]="{
    'esign-header__fixed': fixedHeader
  }">
  <a routerLink="/">
    <img
      *ngIf="!fixedHeader || isMobile"
      src="assets/images/logo-esign.png"
      class="esign-header__logo"
      alt="eSign Workspace">
    <img
      *ngIf="fixedHeader && !isMobile"
      src="assets/images/logo-esign-emblem.png"
      class="esign-header__logo"
      alt="eSign Workspace">
  </a>

  <button
    *ngIf="isMobile"
    class="esign-header__toggle"
    (click)="toggleMenu()"
  >
    <span class="mat-icon">
      {{ isMenuOpen ? 'close' : 'menu' }}
    </span>
  </button>

  <ng-container *ngIf="!isMobile">
    <ul class="esign-header__middle">
      <li>
        <a href="#features" class="esign-header__middle-link" i18n>
          {{ '6599364831830861985' | translate }}
        </a>
      </li>
      <li>
        <a href="#benefits" class="esign-header__middle-link" i18n>
          {{ '5557445106109830312' | translate }}
        </a>
      </li>
      <li>
        <a href="#why-now" class="esign-header__middle-link" i18n>
          {{ '1127390368055065068' | translate }}
        </a>
      </li>
      <li>
        <a href="/dms/blog" class="esign-header__middle-link" i18n>
          {{ '7751010942038334793' | translate }}
        </a>
      </li>
      <li>
        <a routerLink="/pricing" class="esign-header__middle-link" i18n>
          {{ '5080775557941296581' | translate }}
        </a>
      </li>
    </ul>

    <div class="esign-header__right">
      <a [href]="eSignAppPortalUrl" class="lp-button" i18n>
        {{ '4476224839121490369' | translate }}
      </a>
    
      <a [href]="eSignAppSsoUrl" class="lp-button lp-button-primary" i18n>
        {{ '3944214414053829898' | translate }}
      </a>
    </div>
  </ng-container>

  <div *ngIf="isMobile && isMenuOpen" class="esign-header__menu">
    <ul class="esign-header__menu-list">
      <li>
        <a href="#features" class="esign-header__middle-link" i18n>
          {{ '6599364831830861985' | translate }}
        </a>
      </li>
      <li>
        <a href="#benefits" class="esign-header__middle-link" i18n>
          {{ '5557445106109830312' | translate }}
        </a>
      </li>
      <li>
        <a href="#why-now" class="esign-header__middle-link" i18n>
          {{ '1127390368055065068' | translate }}
        </a>
      </li>
      <li>
        <a href="/dms/blog" class="esign-header__middle-link" i18n>
          {{ '7751010942038334793' | translate }}
        </a>
      </li>
      <li>
        <a routerLink="/pricing" class="esign-header__middle-link" i18n>
          {{ '5080775557941296581' | translate }}
        </a>
      </li>
    </ul>

    <a href="#" class="esign-header__menu-button lp-button" i18n>
      {{ '4476224839121490369' | translate }}
    </a>

    <a href="#" class="esign-header__menu-button lp-button lp-button-primary" i18n>
      {{ '3944214414053829898' | translate }}
    </a>
  </div>
</header>
