import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

enum Language {
  ENGLISH = 'en',
  SPANISH = 'es',
}

@Component({
  selector: 'app-footer-esign',
  standalone: true,
  imports: [FormsModule, RouterLink, TranslateModule],
  templateUrl: './esign-footer.layout.html',
  styleUrl: './esign-footer.layout.scss'
})
export class EsignFooterComponent {
  lang = Language.ENGLISH;

  constructor(
    private translate: TranslateService
  ) {
    this.lang = this.translate.currentLang === 'es'
      ? Language.SPANISH
      : Language.ENGLISH;
  }
  
  onSelectLanguage(selectedLang: Language) {
    this.lang = selectedLang;
    this.translate.use(selectedLang);
  }
}
