import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Layout } from '@enum/layout.enum';

@Injectable({
  providedIn: 'root'
})

export class LayoutService {
  private layoutSubject = new Subject<Layout>();

  public layout$ = this.layoutSubject.asObservable();

  setLayout(value: Layout) {
    this.layoutSubject.next(value);
  }
}
