<div class="lp-accordion">
  <div class="lp-accordion-header" (click)="toggleExpanded()">
    <h4 class="lp-accordion-header__title">
      {{ title }}
    </h4>

    <button>
      <ng-container *ngIf="isExpanded; else collapsed">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M3.3335 12.5L8.82164 7.01184C9.47251 6.36096 10.5278 6.36096 11.1787 7.01184L16.6668 12.5" stroke="#C8C8D3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </ng-container>

      <ng-template #collapsed>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M16.6668 7.5L11.1787 12.9882C10.5278 13.639 9.47254 13.639 8.82167 12.9882L3.3335 7.5" stroke="#C8C8D3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </ng-template>
    </button>
  </div>

  <div
    class="lp-accordion-content"
    [ngClass]="{
      'lp-accordion-content__collapsed': !isExpanded
    }"
  >
    <ng-content />
  </div>
</div>