import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(private http: HttpClient) { }

  baseUrl: string = 'https://esign-blog.dmsworkspace.com/wp-json/wp/v2';
  subscribeUrl: string = '/newsletter-api/subscribe';

  getBlogs() {
    return this.http.get<any[]>(`${this.baseUrl}/posts`);
  }

  getImage(id: number) {
    return this.http.get<any>(`${this.baseUrl}/media/${id}`);
  }

  getCategory(categoryId: number) {
    return this.http.get<any>(`${this.baseUrl}/categories?include=${categoryId}`);
  }

  subscribeEmail(email: string) {
    const headers = new HttpHeaders({
      'x-api-key': environment.apiKey,
    });

    const body = new FormData();
    body.append('email', email);

    return this.http.post<any>(this.subscribeUrl, body, { headers });
  }
}
