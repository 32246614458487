<main class="lp-home">
  <div class="lp-container">
    <section class="lp-home-hero">
      <div class="lp-home-hero__top">
        <h1 i18n class="lp-home-hero__title">
          {{ '8556019943849548950' | translate }}
        </h1>
        <p i18n class="lp-home-hero__subtitle">
          {{ '8542677639595804485' | translate }}
        </p>
        <button i18n class="lp-button lp-button-primary">
          {{ '3545785371861413299' | translate }}
        </button>
      </div>

      <figure class="lp-home-hero__image">
        <img src="assets/images/homepage-hero-image.png" alt="Esign Workspace Preview">
      </figure>

      <button class="lp-home-hero__btn-play">
        <span class="lp-home-hero__btn-play-icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="play, go">
              <path id="vector"
                d="M7.70327 1.97292C6.17849 0.986292 4.16666 2.08078 4.16666 3.89693V16.1031C4.16666 17.9193 6.17848 19.0138 7.70327 18.0271L17.1353 11.924C18.5309 11.021 18.531 8.97907 17.1353 8.07602L7.70327 1.97292Z"
                fill="#202033" fill-opacity="0.5" />
            </g>
          </svg>
        </span>
        <span i18n>
          {{ '7752346395249282291' | translate }}
        </span>
      </button>
    </section>

    <!-- Section Features -->
    <div class="lp-section lp-section-features" id="features">
      <div class="lp-section__tag lp-badge">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <g clip-path="url(#clip0_3399_51484)">
            <path
              d="M8.50002 1.16663V5.49996M8.50002 10.5V14.8333M6.00002 7.99996H1.66669M11 7.99996H15.3334M5.83335 5.33329L4.50002 3.99996M11.1667 5.33329L12.5 3.99996M11.1667 10.6666L12.5 12M5.83335 10.6666L4.50002 12"
              stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_3399_51484">
              <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
        <span i18n>
          {{ '7165997532946998319' | translate }}
        </span>
      </div>
      <h1 i18n class="lp-section__title">
        {{ '3661569910453269392' | translate }}
      </h1>

      <div class="lp-section-features__main">
        <ul class="lp-section-features__tabs">
          <li [ngClass]="{
            'active': featureActiveTab === featureTabs.CREATE
          }">
            <button i18n class="lp-section-features__tabs-item" (click)="setFeatureTab(featureTabs.CREATE)">
              {{ '7941428823403788384' | translate }}
            </button>

            <div>
              <h4 i18n class="lp-section-features__tabs-item-title">
                {{ '7396748749070191641' | translate }}
              </h4>
              <p i18n class="lp-section-features__tabs-item-description">
                {{ '3196427916668757248' | translate }}
              </p>
            </div>

            <img [src]="getActiveTabImage()" [alt]="getActiveTabCaption()">
          </li>
          <li [ngClass]="{
            'active': featureActiveTab === featureTabs.REMIND
          }">
            <button i18n class="lp-section-features__tabs-item" (click)="setFeatureTab(featureTabs.REMIND)">
              {{ '4166977879305257929' | translate }}
            </button>

            <div>
              <h4 i18n class="lp-section-features__tabs-item-title">
                {{ '3948041884385247050' | translate }}
              </h4>
              <p i18n class="lp-section-features__tabs-item-description">
                {{ '547535672483141306' | translate }}
              </p>
            </div>

            <img [src]="getActiveTabImage()" [alt]="getActiveTabCaption()">
          </li>
          <li [ngClass]="{
            'active': featureActiveTab === featureTabs.SIGN
          }">
            <button i18n class="lp-section-features__tabs-item" (click)="setFeatureTab(featureTabs.SIGN)">
              {{ '216456550048393878' | translate }}
            </button>

            <div>
              <h4 i18n class="lp-section-features__tabs-item-title">
                {{ '6739253834673487652' | translate }}
              </h4>
              <p i18n class="lp-section-features__tabs-item-description">
                {{ '890900187153831000' | translate }}
              </p>
            </div>

            <img [src]="getActiveTabImage()" [alt]="getActiveTabCaption()">
          </li>
          <li [ngClass]="{
            'active': featureActiveTab === featureTabs.TRACK
          }">
            <button i18n class="lp-section-features__tabs-item" (click)="setFeatureTab(featureTabs.TRACK)">
              {{ '2599899512877501871' | translate }}
            </button>

            <div>
              <h4 i18n class="lp-section-features__tabs-item-title">
                {{ '8218245309245661738' | translate }}
              </h4>
              <p i18n class="lp-section-features__tabs-item-description">
                {{ '3799063867667622316' | translate }}
              </p>
            </div>

            <img [src]="getActiveTabImage()" [alt]="getActiveTabCaption()">
          </li>
        </ul>

        <img [src]="getActiveTabImage()" [alt]="getActiveTabCaption()">
      </div>

      <div class="lp-section-features__other">
        <h2 i18n class="lp-section-features__other-title">{{ '6536935450682867490' | translate }}</h2>

        <div class="lp-section-features__other-card lp-section-features__other-card-horizontal">
          <div>
            <h4 i18n>{{ '1044407220823795215' | translate }}</h4>
            <p i18n>{{ '4971298629077458997' | translate }}</p>
          </div>

          <img src="assets/images/homepage-features-brand.png" alt="Feature Brand & Personalization">
        </div>

        <div class="lp-section-features__other-two-columns">
          <div class="lp-section-features__other-card">
            <div>
              <h4 i18n>{{ '7132164767613301867' | translate }}</h4>
              <p i18n>{{ '8401852359379601072' | translate }}</p>
            </div>

            <img src="assets/images/homepage-features-streamline.png" alt="Feature Streamline Workflows">
          </div>

          <div class="lp-section-features__other-card">
            <div>
              <h4 i18n>{{ '8614403880063295522' | translate }}</h4>
              <p i18n>{{ '3487970728622918691' | translate }}</p>
            </div>

            <img src="assets/images/homepage-features-integration.png" alt="Feature Integrations & API">
          </div>
        </div>

        <div class="lp-section-features__other-three-columns">
          <div class="lp-section-features__other-card">
            <div>
              <h4 i18n>{{ '3513753743889967132' | translate }}</h4>
              <p i18n>{{ '2236966267433700124' | translate }}</p>
            </div>

            <img src="assets/images/homepage-features-security.png" alt="Feature Security">
          </div>

          <div class="lp-section-features__other-card">
            <div>
              <h4 i18n>{{ '7472761080213757384' | translate }}</h4>
              <p i18n>{{ '2677450194083072885' | translate }}</p>
            </div>

            <img src="assets/images/homepage-features-certified.png" alt="Feature Certified Signature">
          </div>

          <div class="lp-section-features__other-card">
            <div>
              <h4 i18n>{{ '6840893204432797011' | translate }}</h4>
              <p i18n>{{ '6927295885864493693' | translate }}</p>
            </div>

            <img src="assets/images/homepage-features-language.png" alt="Feature Multilingual Support">
          </div>
        </div>
      </div>
    </div>
    <!-- End Section Features -->

    <!-- Section Legal -->
    <div class="lp-section lp-section-legal">
      <div class="lp-section-legal__left">
        <div class="lp-section__tag lp-badge">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M8.00001 1.83325V13.4999M8.00001 13.4999H4.50001M8.00001 13.4999H11.5M1.83334 3.83325H5.01859C5.22558 3.83325 5.42973 3.78506 5.61487 3.69249L6.38515 3.30735C6.57029 3.21478 6.77444 3.16659 6.98143 3.16659H9.01859C9.22558 3.16659 9.42973 3.21478 9.61487 3.30735L10.3851 3.69249C10.5703 3.78506 10.7744 3.83325 10.9814 3.83325H14.1667M3.66668 3.83325L1.99176 9.13714C1.80221 9.7374 2.05109 10.4167 2.65342 10.5996C3.33588 10.8067 3.99747 10.8067 4.67994 10.5996C5.28227 10.4167 5.53114 9.7374 5.34159 9.13714L3.66668 3.83325ZM12.3333 3.83325L10.6584 9.13714C10.4689 9.7374 10.7178 10.4167 11.3201 10.5996C12.0026 10.8067 12.6641 10.8067 13.3466 10.5996C13.9489 10.4167 14.1978 9.7374 14.0083 9.13714L12.3333 3.83325Z"
              stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span i18n>
            {{ '6454808903356810505' | translate }}
          </span>
        </div>
        <h1 i18n class="lp-section__title">
          {{ '3196027228210162446' | translate }}
        </h1>
        <p i18n class="lp-section__subtitle">
          {{ '5289225327203486296' | translate }}
        </p>
      </div>

      <div class="lp-section-legal__right">
        <div class="lp-section-legal__image">
          <div class="lp-section-legal__image-text">
            <figure class="lp-section-legal__image-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30" fill="none">
                <path
                  d="M5.60739 21.2395C4.81204 21.3517 3.50277 21.5607 2.63178 20.6091C1.76079 19.6575 1.00759 17.3598 0.676553 15.3063C0.188057 12.2796 0.672834 9.72881 2.04038 8.12417C2.84627 7.17918 3.90385 6.63176 5.09906 6.5446C6.12255 6.46879 7.65189 6.85443 9.12853 9.12289C10.0535 10.5443 10.7571 12.4325 11.059 14.3028C11.4235 16.5605 11.7787 18.9698 10.5358 19.8336C9.83462 20.3214 9.33125 20.4903 8.14162 20.7631C7.16339 20.987 6.40337 21.1267 5.60739 21.2395ZM4.19274 23.1814L10.943 21.8406C11.5134 21.7272 11.9709 22.0293 12.1829 22.9343C12.8784 25.9048 11.8816 29.4591 8.75968 29.4591C5.80329 29.4591 3.83318 26.214 3.51145 24.4697C3.36452 23.6787 3.57406 23.3038 4.19274 23.1814ZM18.6288 15.0328C17.4392 14.7612 16.9358 14.5911 16.2347 14.1034C14.9917 13.2396 15.3469 10.8302 15.7115 8.57249C16.0134 6.7022 16.717 4.81161 17.6419 3.39263C19.1173 1.12416 20.6466 0.738524 21.6714 0.814338C22.8653 0.902092 23.9235 1.44831 24.7294 2.39331C26.0982 3.99795 26.583 6.54878 26.0933 9.57539C25.7616 11.629 25.0109 13.9189 24.1349 14.8782C23.259 15.8376 21.9571 15.6209 21.1593 15.5086C20.3615 15.3964 19.6064 15.2561 18.6288 15.0328ZM22.5771 17.4506L15.8268 16.1098C15.2564 15.9963 14.7989 16.2984 14.5869 17.2034C13.892 20.1739 14.8888 23.7282 18.0107 23.7282C20.9671 23.7282 22.9372 20.4831 23.259 18.7388C23.4053 17.9478 23.1957 17.5729 22.5771 17.4506Z"
                  fill="url(#paint0_linear_3471_46689)" />
                <defs>
                  <linearGradient id="paint0_linear_3471_46689" x1="13.3853" y1="0.805664" x2="13.3853" y2="29.4591"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C7C7E9" />
                    <stop offset="1" stop-color="#6C6CC6" />
                  </linearGradient>
                </defs>
              </svg>
            </figure>

            <span i18n>{{ '7779321973456430774' | translate }}</span>
          </div>

          <div class="lp-section-legal__image-text">
            <figure class="lp-section-legal__image-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                <g clip-path="url(#clip0_3471_46696)">
                  <path
                    d="M17.5819 18.328C17.5819 18.0926 17.4972 17.8889 17.3278 17.7169C17.1584 17.5449 16.9579 17.4589 16.7261 17.4589C16.4944 17.4589 16.2938 17.5449 16.1245 17.7169C15.9551 17.8889 15.8704 18.0926 15.8704 18.328C15.8704 18.5633 15.9551 18.767 16.1245 18.939C16.2938 19.1111 16.4944 19.1971 16.7261 19.1971C16.9579 19.1971 17.1584 19.1111 17.3278 18.939C17.4972 18.767 17.5819 18.5633 17.5819 18.328ZM21.0047 18.328C21.0047 18.0926 20.92 17.8889 20.7507 17.7169C20.5813 17.5449 20.3808 17.4589 20.149 17.4589C19.9172 17.4589 19.7167 17.5449 19.5473 17.7169C19.378 17.8889 19.2933 18.0926 19.2933 18.328C19.2933 18.5633 19.378 18.767 19.5473 18.939C19.7167 19.1111 19.9172 19.1971 20.149 19.1971C20.3808 19.1971 20.5813 19.1111 20.7507 18.939C20.92 18.767 21.0047 18.5633 21.0047 18.328ZM22.7161 15.2862V19.6316C22.7161 19.9937 22.5914 20.3015 22.3418 20.555C22.0922 20.8085 21.7891 20.9352 21.4326 20.9352H1.7511C1.39456 20.9352 1.09149 20.8085 0.841905 20.555C0.592321 20.3015 0.467529 19.9937 0.467529 19.6316V15.2862C0.467529 14.924 0.592321 14.6162 0.841905 14.3628C1.09149 14.1093 1.39456 13.9825 1.7511 13.9825H7.96842L9.77344 15.8293C10.2904 16.3363 10.8966 16.5898 11.5918 16.5898C12.2871 16.5898 12.8932 16.3363 13.4102 15.8293L15.2286 13.9825H21.4326C21.7891 13.9825 22.0922 14.1093 22.3418 14.3628C22.5914 14.6162 22.7161 14.924 22.7161 15.2862ZM18.3707 7.55944C18.5222 7.93061 18.4599 8.24747 18.1835 8.51L12.1935 14.5936C12.0331 14.7656 11.8325 14.8516 11.5918 14.8516C11.3512 14.8516 11.1506 14.7656 10.9902 14.5936L5.00015 8.51C4.72383 8.24747 4.66143 7.93061 4.81296 7.55944C4.9645 7.20638 5.22745 7.02984 5.60183 7.02984H9.02469V0.946235C9.02469 0.710857 9.10937 0.507165 9.27873 0.335159C9.44809 0.163152 9.64865 0.0771484 9.88041 0.0771484H13.3033C13.535 0.0771484 13.7356 0.163152 13.9049 0.335159C14.0743 0.507165 14.159 0.710857 14.159 0.946235V7.02984H17.5819C17.9562 7.02984 18.2192 7.20638 18.3707 7.55944Z"
                    fill="url(#paint0_linear_3471_46696)" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_3471_46696" x1="11.5918" y1="0.0771484" x2="11.5918" y2="20.9352"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C7C7E9" />
                    <stop offset="1" stop-color="#6C6CC6" />
                  </linearGradient>
                  <clipPath id="clip0_3471_46696">
                    <rect width="22.2486" height="20.8581" fill="white" transform="translate(0.467529 0.0771484)" />
                  </clipPath>
                </defs>
              </svg>
            </figure>

            <span i18n>{{ '6101940752635928540' | translate }}</span>
          </div>

          <div class="lp-section-legal__image-text">
            <figure class="lp-section-legal__image-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
                <path
                  d="M3.10985 0.389648C1.48964 0.389648 0.172363 1.71537 0.172363 3.34595V21.0838C0.172363 22.7143 1.48964 24.0401 3.10985 24.0401H14.8598C16.48 24.0401 17.7973 22.7143 17.7973 21.0838V20.1923C17.6734 20.2431 17.5495 20.2846 17.4209 20.317L14.6624 21.0099C14.5248 21.0422 14.3871 21.0653 14.2494 21.0745C14.2081 21.0791 14.1667 21.0838 14.1254 21.0838H11.188C10.908 21.0838 10.6555 20.9267 10.5316 20.6773L10.1277 19.8597C10.0497 19.7026 9.89362 19.6056 9.72379 19.6056C9.55397 19.6056 9.39333 19.7026 9.31989 19.8597L8.91599 20.6773C8.78288 20.9498 8.49372 21.1115 8.19538 21.0838C7.89704 21.056 7.64001 20.8482 7.5574 20.5618L6.78171 17.9889L6.33191 19.504C6.05193 20.4417 5.19363 21.0838 4.22059 21.0838H3.84423C3.44032 21.0838 3.10985 20.7512 3.10985 20.3447C3.10985 19.9382 3.44032 19.6056 3.84423 19.6056H4.22059C4.54647 19.6056 4.83104 19.3931 4.92283 19.079L5.60672 16.7925C5.76277 16.2705 6.24012 15.9102 6.78171 15.9102C7.32331 15.9102 7.80066 16.2705 7.95671 16.7925L8.48913 18.5755C8.82878 18.2891 9.26022 18.1275 9.7192 18.1275C10.449 18.1275 11.1145 18.5432 11.4404 19.1991L11.6423 19.6056H12.0508C11.9086 19.1991 11.881 18.7557 11.9866 18.3215L12.6751 15.5453C12.8036 15.0233 13.0698 14.5522 13.4461 14.1734L17.7973 9.79438V7.7804H11.9223C11.1099 7.7804 10.4536 7.11985 10.4536 6.30225V0.389648H3.10985ZM11.9223 0.389648V6.30225H17.7973L11.9223 0.389648ZM25.4072 6.8427C24.6912 6.1221 23.53 6.1221 22.8094 6.8427L21.46 8.20075L24.7188 11.4804L26.0682 10.1223C26.7842 9.40175 26.7842 8.23309 26.0682 7.50787L25.4072 6.8427ZM14.488 15.2174C14.2999 15.4067 14.1667 15.6423 14.1025 15.9056L13.414 18.6818C13.3498 18.9358 13.4232 19.1991 13.6068 19.3839C13.7904 19.5687 14.052 19.6426 14.3044 19.5779L17.0629 18.885C17.32 18.8203 17.5586 18.6864 17.7468 18.497L23.6769 12.5243L20.4181 9.2447L14.488 15.2174Z"
                  fill="url(#paint0_linear_3471_46704)" />
                <defs>
                  <linearGradient id="paint0_linear_3471_46704" x1="13.3888" y1="0.389648" x2="13.3888" y2="24.0401"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C7C7E9" />
                    <stop offset="1" stop-color="#6C6CC6" />
                  </linearGradient>
                </defs>
              </svg>
            </figure>

            <span i18n>{{ '2677450194083072885' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Section Legal -->

    <!-- Section Benefits -->
    <div class="lp-section lp-section-benefits" id="benefits">
      <div class="lp-section__tag lp-badge">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
          <path
            d="M6.16669 5.16667L4.66669 6.66667L6.16669 8.16667M9.91423 12.5858L14.4031 8.09695C15.1904 7.30965 15.1831 6.03099 14.387 5.25263L12.1544 3.0699C11.7807 2.70456 11.2788 2.5 10.7562 2.5H6.24381C5.72121 2.5 5.21936 2.70456 4.84567 3.0699L2.61304 5.25263C1.8169 6.03099 1.80967 7.30965 2.59697 8.09695L7.08581 12.5858C7.86685 13.3668 9.13318 13.3668 9.91423 12.5858Z"
            stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span i18n>
          {{ '3426105584773786892' | translate }}
        </span>
      </div>
      <h1 i18n class="lp-section__title">
        {{ '6972975666100432538' | translate }}
      </h1>

      <div class="lp-section-benefits-list">
        <div class="lp-section-benefits-list__card">
          <img class="lp-section-benefits-list__card-image" src="assets/images/homepage-benefits-1.png"
            alt="Minutes, Not Days">
          <h4 i18n class="lp-section-benefits-list__card-title">
            {{ '7652999472342102844' | translate }}
          </h4>
          <p i18n class="lp-section-benefits-list__card-caption">
            {{ '3953898926755246873' | translate }}
          </p>
        </div>

        <div class="lp-section-benefits-list__card">
          <img class="lp-section-benefits-list__card-image" src="assets/images/homepage-benefits-2.png"
            alt="Minutes, Not Days">
          <h4 i18n class="lp-section-benefits-list__card-title">
            {{ '121164948212502586' | translate }}
          </h4>
          <p i18n class="lp-section-benefits-list__card-caption">
            {{ '6860774842875943406' | translate }}
          </p>
        </div>

        <div class="lp-section-benefits-list__card">
          <img class="lp-section-benefits-list__card-image" src="assets/images/homepage-benefits-3.png"
            alt="Minutes, Not Days">
          <h4 i18n class="lp-section-benefits-list__card-title">
            {{ '7023380837251384670' | translate }}
          </h4>
          <p i18n class="lp-section-benefits-list__card-caption">
            {{ '3303178159633152640' | translate }}
          </p>
        </div>

        <div class="lp-section-benefits-list__card">
          <img class="lp-section-benefits-list__card-image" src="assets/images/homepage-benefits-4.png"
            alt="Minutes, Not Days">
          <h4 i18n class="lp-section-benefits-list__card-title">
            {{ '915477889608820727' | translate }}
          </h4>
          <p i18n class="lp-section-benefits-list__card-caption">
            {{ '326189026754194895' | translate }}
          </p>
        </div>
      </div>

      <div class="lp-section-benefits-quote">
        <p i18n class="lp-section-benefits-quote__text">
          {{ '4026186962086588727' | translate }}
        </p>
        <div class="lp-section-benefits-quote__profile">
          <figure class="lp-section-benefits-quote__profile-photo">
            <img src="assets/images/klehia-photo.png" alt="Klehia Pasantes">
          </figure>
          <div>
            <h4 i18n class="lp-section-benefits-quote__profile-name">
              Klehia Pasantes
            </h4>
            <p i18n class="lp-section-benefits-quote__profile-occupation">
              {{ '92747377828404622' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Section Benefits -->

    <!-- Section Why Now -->
    <div class="lp-section lp-section-why" id="why-now">
      <div class="lp-section__tag lp-badge">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M2.57409 7.50118L5.64076 2.16785C5.7597 1.96101 5.9801 1.8335 6.2187 1.8335H10.921C11.4166 1.8335 11.7389 2.35504 11.5173 2.79831L10.6488 4.53535C10.4271 4.97862 10.7494 5.50016 11.245 5.50016H12.8902C13.4841 5.50016 13.7816 6.21826 13.3616 6.63823L6.0688 13.931C5.60134 14.3985 4.8118 13.9738 4.94424 13.3261L5.76742 9.30039C5.85194 8.88707 5.53614 8.50016 5.11427 8.50016H3.15203C2.63936 8.50016 2.31854 7.94563 2.57409 7.50118Z"
            stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span i18n>
          {{ '1899414993079674626' | translate }}
        </span>
      </div>
      <h1 i18n class="lp-section__title">
        {{ '4777506467207118488' | translate }}
      </h1>

      <div class="lp-section-statistics">
        <div class="lp-section-statistics__image">
          <h4 i18n class="lp-section-statistics__image-title">
            {{ '523682189393828192' | translate }}
          </h4>
          <p i18n class="lp-section-statistics__image-caption">
            {{ '7245881862657195279' | translate }}
          </p>
        </div>
        <div class="lp-section-statistics__cards">
          <div class="lp-section-statistics__card">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M26.2908 19.3812C26.7501 18.3478 27.0053 17.2037 27.0053 16C27.0053 11.3976 23.2744 7.66662 18.672 7.66662H13.3386C13.1709 7.66662 13.0109 7.59454 12.8991 7.46943C11.6114 6.02777 10.1014 5.41308 8.98675 5.16196C8.26837 5.00011 7.66665 5.59691 7.66665 6.33329V9.31371C7.66665 9.68425 7.50838 10.0343 7.25509 10.3048C6.73541 10.8596 6.29078 11.4857 5.93736 12.1666C5.6835 12.6558 5.1982 13 4.6471 13H3.66665C2.93027 13 2.33331 13.5969 2.33331 14.3333V17.6666C2.33331 18.403 2.93027 19 3.66665 19H4.6471C5.1982 19 5.6835 19.3441 5.93736 19.8333C6.29078 20.5142 6.73541 21.1403 7.25509 21.6952C7.50838 21.9656 7.66665 22.3157 7.66665 22.6862V25.6666C7.66665 26.403 8.2636 27 8.99998 27H12.3333C13.0697 27 13.6666 26.403 13.6666 25.6666C13.6666 24.9302 14.2636 24.3333 15 24.3333H17C17.7364 24.3333 18.3333 24.9302 18.3333 25.6666C18.3333 26.403 18.9303 27 19.6666 27H23C23.7364 27 24.3333 26.403 24.3333 25.6666V22.6975C24.3333 22.3261 24.4923 21.9754 24.7465 21.7048C25.3833 21.027 25.908 20.2425 26.2908 19.3812ZM26.2908 19.3812C26.78 19.9633 27.5134 20.3333 28.3333 20.3333C29.8061 20.3333 31 19.1394 31 17.6666C31 17.036 30.7811 16.4565 30.4151 16M10.6666 14.3333H11.3333M11.6666 14.3333C11.6666 14.7015 11.3682 15 11 15C10.6318 15 10.3333 14.7015 10.3333 14.3333C10.3333 13.9651 10.6318 13.6666 11 13.6666C11.3682 13.6666 11.6666 13.9651 11.6666 14.3333Z"
                stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

            <div>
              <h4 i18n class="lp-section-statistics__card-title">
                {{ '3504643179909235184' | translate }}
              </h4>
              <p i18n class="lp-section-statistics__card-caption">
                {{ '7941275548909539983' | translate }}
              </p>
            </div>
          </div>

          <div class="lp-section-statistics__card">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.66663 9.00016C2.66663 6.97512 4.30825 5.3335 6.33329 5.3335H25.6666C27.6917 5.3335 29.3333 6.97512 29.3333 9.00016V11.0002C29.3333 11.5524 28.8856 12.0002 28.3333 12.0002C27.781 12.0002 27.3333 11.5524 27.3333 11.0002V9.00016C27.3333 8.07969 26.5871 7.3335 25.6666 7.3335H6.33329C5.41282 7.3335 4.66663 8.07969 4.66663 9.00016V23.0002C4.66663 23.9206 5.41282 24.6668 6.33329 24.6668H13.6666C14.2189 24.6668 14.6666 25.1145 14.6666 25.6668C14.6666 26.2191 14.2189 26.6668 13.6666 26.6668H6.33329C4.30825 26.6668 2.66663 25.0252 2.66663 23.0002V9.00016ZM24 14.6668C24.5522 14.6668 25 15.1145 25 15.6668C25 17.5124 25.4089 18.613 26.0647 19.2687C26.7205 19.9245 27.8211 20.3335 29.6666 20.3335C30.2189 20.3335 30.6666 20.7812 30.6666 21.3335C30.6666 21.8858 30.2189 22.3335 29.6666 22.3335C27.8211 22.3335 26.7205 22.7425 26.0647 23.3982C25.4089 24.054 25 25.1546 25 27.0002C25 27.5524 24.5522 28.0002 24 28.0002C23.4477 28.0002 23 27.5524 23 27.0002C23 25.1546 22.591 24.054 21.9352 23.3982C21.2794 22.7425 20.1788 22.3335 18.3333 22.3335C17.781 22.3335 17.3333 21.8858 17.3333 21.3335C17.3333 20.7812 17.781 20.3335 18.3333 20.3335C20.1788 20.3335 21.2794 19.9245 21.9352 19.2687C22.591 18.613 23 17.5124 23 15.6668C23 15.1145 23.4477 14.6668 24 14.6668ZM24 19.8717C23.8132 20.1642 23.5972 20.4352 23.3494 20.683C23.1017 20.9307 22.8307 21.1467 22.5381 21.3335C22.8307 21.5203 23.1017 21.7363 23.3494 21.984C23.5972 22.2318 23.8132 22.5027 24 22.7953C24.1867 22.5027 24.4027 22.2318 24.6505 21.984C24.8982 21.7363 25.1692 21.5203 25.4618 21.3335C25.1692 21.1467 24.8982 20.9307 24.6505 20.683C24.4027 20.4352 24.1867 20.1642 24 19.8717Z"
                fill="#6C6CC6" />
              <path
                d="M9.33329 10.6668C9.33329 11.4032 8.73634 12.0002 7.99996 12.0002C7.26358 12.0002 6.66663 11.4032 6.66663 10.6668C6.66663 9.93045 7.26358 9.3335 7.99996 9.3335C8.73634 9.3335 9.33329 9.93045 9.33329 10.6668Z"
                fill="#6C6CC6" />
              <path
                d="M13.3333 10.6668C13.3333 11.4032 12.7363 12.0002 12 12.0002C11.2636 12.0002 10.6666 11.4032 10.6666 10.6668C10.6666 9.93045 11.2636 9.3335 12 9.3335C12.7363 9.3335 13.3333 9.93045 13.3333 10.6668Z"
                fill="#6C6CC6" />
              <path
                d="M16 12.0002C16.7363 12.0002 17.3333 11.4032 17.3333 10.6668C17.3333 9.93045 16.7363 9.3335 16 9.3335C15.2636 9.3335 14.6666 9.93045 14.6666 10.6668C14.6666 11.4032 15.2636 12.0002 16 12.0002Z"
                fill="#6C6CC6" />
            </svg>

            <div>
              <h4 i18n class="lp-section-statistics__card-title">
                {{ '2021278035286637822' | translate }}
              </h4>
              <p i18n class="lp-section-statistics__card-caption">
                {{ '8721767818439635649' | translate }}
              </p>
            </div>
          </div>

          <div class="lp-section-statistics__card">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M17.781 4.44755L18.3114 3.91722L18.3114 3.91722L17.781 4.44755ZM24.8856 11.5521L25.4159 11.0218V11.0218L24.8856 11.5521ZM11.6666 16.9165C11.2524 16.9165 10.9166 17.2523 10.9166 17.6665C10.9166 18.0807 11.2524 18.4165 11.6666 18.4165V16.9165ZM16.3333 18.4165C16.7475 18.4165 17.0833 18.0807 17.0833 17.6665C17.0833 17.2523 16.7475 16.9165 16.3333 16.9165V18.4165ZM11.6666 22.2498C11.2524 22.2498 10.9166 22.5856 10.9166 22.9998C10.9166 23.4141 11.2524 23.7498 11.6666 23.7498V22.2498ZM20.3333 23.7498C20.7475 23.7498 21.0833 23.4141 21.0833 22.9998C21.0833 22.5856 20.7475 22.2498 20.3333 22.2498V23.7498ZM8.99998 4.4165H15.8954V2.9165H8.99998V4.4165ZM24.9166 13.4377V25.6665H26.4166V13.4377H24.9166ZM17.2507 4.97788L24.3553 12.0825L25.4159 11.0218L18.3114 3.91722L17.2507 4.97788ZM7.08331 25.6665V6.33317H5.58331V25.6665H7.08331ZM23 27.5832H8.99998V29.0832H23V27.5832ZM5.58331 25.6665C5.58331 27.5535 7.11301 29.0832 8.99998 29.0832V27.5832C7.94143 27.5832 7.08331 26.725 7.08331 25.6665H5.58331ZM26.4166 13.4377C26.4166 12.5316 26.0567 11.6625 25.4159 11.0218L24.3553 12.0825C24.7147 12.4419 24.9166 12.9294 24.9166 13.4377H26.4166ZM15.8954 4.4165C16.4037 4.4165 16.8913 4.61844 17.2507 4.97788L18.3114 3.91722C17.6706 3.27647 16.8016 2.9165 15.8954 2.9165V4.4165ZM24.9166 25.6665C24.9166 26.725 24.0585 27.5832 23 27.5832V29.0832C24.887 29.0832 26.4166 27.5535 26.4166 25.6665H24.9166ZM8.99998 2.9165C7.11301 2.9165 5.58331 4.4462 5.58331 6.33317H7.08331C7.08331 5.27462 7.94143 4.4165 8.99998 4.4165V2.9165ZM16.25 4.33317V9.6665H17.75V4.33317H16.25ZM19.6666 13.0832H25V11.5832H19.6666V13.0832ZM16.25 9.6665C16.25 11.5535 17.7797 13.0832 19.6666 13.0832V11.5832C18.6081 11.5832 17.75 10.725 17.75 9.6665H16.25ZM11.6666 18.4165H16.3333V16.9165H11.6666V18.4165ZM11.6666 23.7498H20.3333V22.2498H11.6666V23.7498Z"
                fill="#6C6CC6" />
            </svg>

            <div>
              <h4 i18n class="lp-section-statistics__card-title">
                {{ '1558528493231745205' | translate }}
              </h4>
              <p i18n class="lp-section-statistics__card-caption">
                {{ '6201321300585932628' | translate }}
              </p>
            </div>
          </div>

          <div class="lp-section-statistics__card">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <circle cx="20" cy="16.0002" r="9.66667" stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M2.33337 16.0002H5.66671M3.66671 21.6668H7.00004M3.66671 10.3335H7.00004" stroke="#6C6CC6"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20 11.6665V15.9998L22.6667 18.6665" stroke="#6C6CC6" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

            <div>
              <h4 i18n class="lp-section-statistics__card-title">
                {{ '8922417669114158680' | translate }}
              </h4>
              <p i18n class="lp-section-statistics__card-caption">
                {{ '1461223808433403407' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Section Why Now -->
  </div>
</main>